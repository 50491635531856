<template>
    <Header></Header>

    <div class="main-height">
        <div class="container">
            <p>
                All sales of subscription of services are final. All our subscription services
                are non-recurring and do not auto-renew. </p>
            <p>
                Prices for our Services may change at any time, and the services do not provide
                price protection or refunds in case of a price reduction or promotional
                offering.</p>
            <p>
                If the Site or the Service becomes unavailable following a transaction but
                before your downloading or streaming any materials, data, text, images, video,
                or audio from the Site, your sole remedy is a refund. If technical problems
                prevent or unreasonably delay delivery of your product, your exclusive and sole
                remedy is either replacement or refund of the price paid, as determined by <a href="http://procricket.tv/"
                    title="http://Procricket.tv" data-renderer-mark="true" class="css-tgpl01"
                    style="color: #0052CC; text-decoration: none;">Procricket.tv.</a></p>
            <p>
                If you (the customer) decide to cancel before your subscription ends, your
                account will not be prorated, discounted, or refunded. Refunds/prorations are
                only applicable to the below criteria.</p>
            <ul>
                <li>
                    <p>Refunded, if the subscription is canceled within 12 hrs.</p>
                </li>            
                <li>
                    <p>Refunded, if the subscription is canceled within 72 hrs and has not watched any content. </p>
                </li>
            </ul>
            <p>
                The customer may cancel their subscription by sending an email to
                <a href="mailto:support@procricket.tv" title="mailto:support@procricket.tv" style="color: #0052CC; text-decoration: none;">
                    support@procricket.tv</a>. Please keep in mind that you are solely responsible
                for properly canceling your account. You can also contact support if you need
                help.
            </p>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: 'Refund Policy',
    components: {
        Header,
        Footer
    }
}
</script>

<style>
@import '../assets/style.css';
</style>